import classNames from 'classnames/bind';
import { Link, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import 'react-toastify/ReactToastify.min.css';

import Button from '../../components/Button';
import styles from './Register.module.scss';
import Input from '../../components/Input';
import request from '../../utils/request';
import { useGlobalContext } from '../../hooks/useGlobalContext';
import { routes } from '../../config';
const cx = classNames.bind(styles);
function Login() {
    const name = useRef();
    const email = useRef();
    const password = useRef();
    const confirmPassword = useRef();
    const btnRegister = useRef();
    const navigate = useNavigate();
    const { toastPromise } = useGlobalContext();
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (btnRegister.current) {
                btnRegister.current.click();
            }
        }
    };

    return (
        <div className={cx('wraper')}>
            <div>
                <Input
                    ref={name}
                    placeholder="Nhập họ và tên"
                    validates={{ require: 'Bắt buộc nhập tên !' }}
                    onKeyDown={handleKeyDown}
                ></Input>
                <Input
                    ref={email}
                    placeholder="Email hoặc số điện thoại"
                    validates={{ require: 'Bắt buộc nhập email !', email: 'Đây không phải email !' }}
                    onKeyDown={handleKeyDown}
                ></Input>
                <Input
                    ref={password}
                    type="password"
                    placeholder="Mật khẩu"
                    validates={{
                        require: 'Bắt buộc nhập mật khẩu !',
                        min: { value: 6, message: 'Mật khẩu tối thiểu phải 6 ký tự !' },
                    }}
                    onKeyDown={handleKeyDown}
                ></Input>
                <Input
                    ref={confirmPassword}
                    type="password"
                    placeholder="Gõ lại mật khẩu"
                    validates={{
                        require: 'Hãy gõ lại mật khẩu !',
                        confirm: { value: password, message: 'Mật khẩu gõ lại không đúng !' },
                    }}
                    onKeyDown={handleKeyDown}
                ></Input>
            </div>

            <div className={cx('login-wraper')}>
                <Button
                    ref={btnRegister}
                    onClick={async () => {
                        const toastRegister = toastPromise('Đang đăng ký...');
                        try {
                            await request.post('verify_register', { email: email.current.getValue() });
                            toastRegister.success('Thông tin nhập vào thành công. Mời bạn xác thực email !');
                            navigate(routes.verifyEmail, {
                                state: {
                                    name: name.current.getValue(),
                                    email: email.current.getValue(),
                                    password: password.current.getValue(),
                                },
                            });
                        } catch (error) {
                            toastRegister.error(error.response ? error.response.data : error);
                        }
                    }}
                    className={cx('btn-login')}
                    primary
                    validateInput={[name, email, password, confirmPassword]}
                >
                    Đăng ký
                </Button>
            </div>
            <div className={cx('forgot-pwr')}>
                <Link to={''}>Quên mật khẩu</Link>
            </div>
            <hr></hr>
            <div className={cx('register-wraper')}>
                <Button to={routes.login} primary className={cx('btn-register')}>
                    Đăng nhập
                </Button>
            </div>
        </div>
    );
}

export default Login;
