import classNames from 'classnames/bind';

import styles from './NavList.module.scss';
import Button from '../../../components/Button';
const cx = classNames.bind(styles);

function NavList({ menus, classNameButton }) {
    return (
        <div className={cx('wraper')}>
            {menus &&
                menus.map((item, index) => {
                    const classes = cx('button', classNameButton, {
                        spacer: item.spacer,
                        active: item.active,
                    });
                    return (
                        <Button key={index} {...item.event} className={classes} leftIcon={item.icon}>
                            <span className={cx('title')}>{item.title}</span>
                        </Button>
                    );
                })}
        </div>
    );
}
export default NavList;
