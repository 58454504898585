const convertExampleToText = (listExample, charEnd = '\n') => {
    let result = '';
    listExample.forEach((part) => {
        result += "'" + part.name + charEnd;
        part.questions.forEach((question, index) => {
            result += (index === 0 ? '' : charEnd) + question.name + charEnd;
            question.answers.forEach((answer) => {
                result += (answer.is_correct ? '*' : '') + answer.option + charEnd;
            });
        });
    });
    return result;
};
export default convertExampleToText;
