import classNames from 'classnames/bind';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import styles from './ListAnswers.module.scss';
import Button from '../../../../components/Button';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
const cx = classNames.bind(styles);
function ListAnswers({ isChange, save, data }, ref) {
    const [checked, setChecked] = useState(1);
    const [answers, setAnswers] = useState(data);
    const [changed, setChanged] = useState(isChange());
    useEffect(() => {
        setAnswers(JSON.parse(JSON.stringify(data)));
    }, [data]);
    useEffect(() => {
        setChanged(isChange());
        answers.forEach((item, index) => {
            item.is_correct && setChecked(index);
        });
    }, [answers, isChange]);
    useImperativeHandle(ref, () => ({
        getAnswers() {
            return answers;
        },
    }));

    const handleChangeAnswer = (e, index) => {
        setAnswers((prev) => {
            const newAnswers = [...prev];
            newAnswers[index].option = e.target.value;
            return newAnswers;
        });
    };
    const handleChecked = (index) => {
        setAnswers((prev) => {
            const newAnswers = [...prev];
            newAnswers.forEach((item) => {
                item.is_correct = false;
            });
            newAnswers[index].is_correct = true;
            return newAnswers;
        });
        setChecked(index);
    };

    return (
        <div className={cx('wrapper')}>
            {answers.map((answer, index) => {
                return (
                    <div className={cx('answer-group')} key={index}>
                        <input
                            className={cx('check')}
                            checked={index === checked}
                            onChange={() => {
                                handleChecked(index);
                            }}
                            type="radio"
                        ></input>
                        <textarea
                            value={answer.option}
                            onChange={(e) => {
                                handleChangeAnswer(e, index);
                            }}
                            spellCheck={false}
                            className={cx('input-area')}
                        ></textarea>
                        <Button
                            className={cx('button')}
                            onClick={() => {
                                setAnswers((prev) => {
                                    const newAnswers = [...prev];
                                    newAnswers.splice(index, 1);
                                    return newAnswers;
                                });
                            }}
                            primary
                            icon={faTrash}
                        ></Button>
                    </div>
                );
            })}
            <div className={cx('action')}>
                <Button
                    primary
                    disable={answers[answers.length - 1] ? answers[answers.length - 1].option === '' : false}
                    onClick={() => {
                        setAnswers((prev) => {
                            const newAnswers = [...prev];
                            newAnswers.push({ option: '', is_correct: false });
                            return newAnswers;
                        });
                    }}
                >
                    Thêm câu trả lời
                </Button>
                <div style={{ display: 'flex' }}>
                    <Button
                        disable={!changed}
                        primary
                        onClick={() => {
                            save();
                        }}
                    >
                        Chấp nhận
                    </Button>
                    <Button
                        disable={!changed}
                        danger
                        onClick={() => {
                            setAnswers(JSON.parse(JSON.stringify(data)));
                        }}
                    >
                        Hủy
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default forwardRef(ListAnswers);
