function shuffle(arra1) {
    var ctr = arra1.length,
        temp,
        index;
    while (ctr > 0) {
        index = Math.floor(Math.random() * ctr);
        ctr--;
        temp = arra1[ctr];
        arra1[ctr] = arra1[index];
        arra1[index] = temp;
    }
    return arra1;
}
const shuffleExample = (exam, isQuestionShuffle, isAnswerShuffle) => {
    const newExam = [...exam];
    if (isAnswerShuffle || isQuestionShuffle) {
        newExam.forEach((item) => {
            if (isAnswerShuffle) {
                item.questions.forEach((question) => {
                    shuffle(question.answers);
                });
            }

            if (isQuestionShuffle) {
                shuffle(item.questions);
            }
        });
    }

    return newExam;
};

export default shuffleExample;
