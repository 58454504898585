import classNames from 'classnames/bind';
import styles from './AnswerList.module.scss';
import Button from '../../../../components/Button';
import { useEffect, useState } from 'react';
const cx = classNames.bind(styles);

function AnswerList({ question = [], handleChooseAnswer }) {
    const [choose, setChoose] = useState(question.choose);
    useEffect(() => {
        setChoose(question.choose);
    }, [question]);

    return (
        <div className={cx('answer-list')}>
            {question.answers.map((answer, index) => {
                const isCorrect = choose + 1 && answer.is_correct;
                return (
                    <Button
                        onClick={() => {
                            setChoose(index);
                            handleChooseAnswer(index, {
                                correct: answer.is_correct,
                            });
                        }}
                        disable={choose + 1}
                        userselect={choose + 1}
                        key={index}
                        className={cx('button')}
                    >
                        <div className={cx('answer-item')}>
                            <input type="radio" readOnly checked={choose === index}></input>
                            <div
                                className={cx('answer-content', {
                                    correct: isCorrect,
                                    wrong: !isCorrect && choose === index,
                                })}
                                dangerouslySetInnerHTML={{ __html: answer.option }}
                            ></div>
                        </div>
                    </Button>
                );
            })}
        </div>
    );
}

export default AnswerList;
