import { Navigate } from 'react-router-dom';
import { memo } from 'react';

import { useAuth } from '../../hooks/useAuth';
import { routes } from '../../config';
function ProtectedRoute({ children }) {
    const { auth } = useAuth();
    if (!auth()) {
        return <Navigate to={routes.login} state={{ message: 'Trang web này bắt buộc đăng nhập !' }} />;
    }
    return children;
}
export default memo(ProtectedRoute);
