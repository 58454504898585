import classNames from 'classnames/bind';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import request from '../../utils/request';
import styles from './EditExample.module.scss';
import Input from '../../components/Input';
import Button from '../../components/Button';
import ListAnswers from './components/ListAnswers';
import Popup from 'reactjs-popup';
import { useGlobalContext } from '../../hooks/useGlobalContext';
import { routes } from '../../config';
const cx = classNames.bind(styles);
const initExample = [
    {
        name: 'Phần 1',
        questions: [
            {
                name: 'Có thể đề thi này bị lỗi !',
                answers: [],
            },
        ],
    },
];
function EditExample() {
    const [listExample, setListExample] = useState(initExample);
    const [example, setExample] = useState([]);
    const { subject } = useParams();
    const [questionName, setQuestionName] = useState('');
    const [answers, setAnswers] = useState([]);
    const [curentPart, setCurentPart] = useState(0);
    const [curentQuestion, setCurentQuestion] = useState(0);
    const answersRef = useRef();
    const [popupWarning, setPopupWarning] = useState({ state: false, message: '', next: () => {}, event: () => {} });
    const { toastPromise, showToast } = useGlobalContext();
    const creditsRef = useRef('');
    const sectorRef = useRef('');
    const nameExampleRef = useRef('');
    const navigate = useNavigate();

    const save = () => {
        setListExample((prev) => {
            const newListExample = [...prev];
            newListExample[curentPart].questions[curentQuestion] = {
                name: questionName,
                answers: answersRef.current.getAnswers(),
            };
            return newListExample;
        });
    };
    const isChange = () => {
        const questionNotChange = JSON.stringify({
            name: listExample[curentPart].questions[curentQuestion].name,
            answers: answers,
        });
        const questionChange = JSON.stringify({
            name: questionName,
            answers: answersRef.current !== undefined ? answersRef.current.getAnswers() : answers,
        });

        return questionNotChange !== questionChange;
    };

    useEffect(() => {
        request
            .get('/getMyQuestion', { params: { id: subject } })
            .then((data) => {
                const exam = JSON.parse(data.data.question);
                setExample(data.data);
                setListExample([...exam]);
            })
            .catch(() => {
                setListExample([...initExample]);
                showToast('Bạn không có quyền sửa đề thi này !');
                navigate(routes.myExam);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setQuestionName(listExample[curentPart].questions[curentQuestion].name);
        setAnswers([...listExample[curentPart].questions[curentQuestion].answers]);
    }, [curentPart, curentQuestion, listExample]);
    if (example.length <= 0) {
        return <div></div>;
    }

    return (
        <div className={cx('wrapper')}>
            <div className={cx('info', 'box')}>
                <h1 className={cx('header')}>Thông tin đề thi</h1>
                <Input
                    ref={nameExampleRef}
                    defaultValue={example.name}
                    validates={{ require: 'Bắt buộc nhập tên đề thi !' }}
                    toast
                    title="Tên đề thi"
                    type="text"
                ></Input>
                <Input
                    style={{ margin: '0 16px' }}
                    defaultValue={example.credits}
                    validates={{ require: 'Bắt buộc nhập số tín chỉ !' }}
                    ref={creditsRef}
                    title="Số tín chỉ"
                    toast
                    type="number"
                ></Input>
                <Input
                    ref={sectorRef}
                    title="Ngành"
                    type="select"
                    defaultValue={example.sector}
                    data={[
                        { title: 'Công nghệ thông tin' },
                        { title: 'Ngôn ngữ trung' },
                        { title: 'Công nghệ kỹ thuật điện tử' },
                        { title: 'Răng hàm mặt' },
                    ]}
                ></Input>
            </div>
            <div className={cx('container')}>
                <div className={cx('content', 'box')}>
                    <div className={cx('question')}>
                        <textarea
                            value={questionName}
                            onChange={(e) => setQuestionName(e.target.value)}
                            className={cx('textarea')}
                            spellCheck={false}
                        ></textarea>
                    </div>
                    <div className={cx('answers')}>
                        <ListAnswers ref={answersRef} isChange={isChange} save={save} data={answers}></ListAnswers>
                    </div>
                </div>
                <div className={cx('example-table')}>
                    <div className={cx('part', 'box')}>
                        {/* Handle change part*/}
                        {listExample.map((part, index) => {
                            const _props = index === curentPart ? { primary: true } : { outline: true };
                            return (
                                <Button
                                    key={index}
                                    {..._props}
                                    className={cx('button')}
                                    // Xóa part
                                    // onKeyDown={(e) => {
                                    //     if (e.key === 'Delete') {
                                    //         if (listExample[curentPart].questions.length <= 1) {
                                    //             showToast('Không thể xóa hết câu hỏi trong 1 phần !');
                                    //             return;
                                    //         }
                                    //         setCurentQuestion(listExample[curentPart].questions.length - 2);
                                    //         setListExample((prev) => {
                                    //             const newExam = [...prev];
                                    //             newExam.splice(curentPart, 1);

                                    //             return newExam;
                                    //         });
                                    //     }
                                    // }}
                                    onClick={(e) => {
                                        if (isChange()) {
                                            setPopupWarning((prev) => ({
                                                ...prev,
                                                state: true,
                                                message: 'Bạn muốn thay đổi câu hỏi vừa rồi không !',
                                                next: () => {
                                                    setCurentPart(index);
                                                    setCurentQuestion(0);
                                                },
                                            }));
                                        } else {
                                            setCurentPart(index);
                                            setCurentQuestion(0);
                                        }
                                    }}
                                    small
                                >
                                    {part.name}
                                </Button>
                            );
                        })}
                    </div>
                    <div className={cx('group-question', 'box')}>
                        <div className={cx('question')}>
                            {/* Handle change question */}
                            {listExample[curentPart].questions.map((item, index) => {
                                const _props = index === curentQuestion ? { primary: true } : { outline: true };
                                return (
                                    <Button
                                        key={index}
                                        onClick={() => {
                                            isChange()
                                                ? setPopupWarning((prev) => ({
                                                      ...prev,
                                                      state: true,
                                                      message: 'Bạn muốn thay đổi câu hỏi vừa rồi không !',
                                                      next: () => {
                                                          setCurentQuestion(index);
                                                      },
                                                  }))
                                                : setCurentQuestion(index);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Delete') {
                                                if (listExample[curentPart].questions.length <= 1) {
                                                    showToast('Không thể xóa hết câu hỏi trong một phần !');
                                                    return;
                                                }
                                                setCurentQuestion(listExample[curentPart].questions.length - 2);
                                                setListExample((prev) => {
                                                    const newExam = [...prev];
                                                    newExam[curentPart].questions.splice(curentQuestion, 1);

                                                    return newExam;
                                                });
                                            }
                                        }}
                                        className={cx('button')}
                                        {..._props}
                                        small
                                    >
                                        {index + 1}
                                    </Button>
                                );
                            })}
                        </div>
                        <div className={cx('action')}>
                            {/* Thêm câu hỏi */}
                            <Button
                                primary
                                onClick={() => {
                                    setListExample((prev) => {
                                        setCurentQuestion(listExample[curentPart].questions.length);
                                        const newExam = [...prev];
                                        newExam[curentPart].questions.push({ name: '', answers: [] });
                                        return newExam;
                                    });
                                }}
                            >
                                Thêm câu hỏi
                            </Button>
                            {/* Lưu thay đổi */}
                            <Button
                                primary
                                validateInput={[creditsRef, sectorRef, nameExampleRef]}
                                onClick={() => {
                                    const update = () => {
                                        const count_question = listExample.reduce((total, item) => {
                                            total += item.questions.length;
                                            return total;
                                        }, 0);
                                        const toastUpdate = toastPromise('Đang chỉnh sửa đề thi !');
                                        request
                                            .post('/update-myexample', {
                                                id: subject,
                                                count_question,
                                                name: nameExampleRef.current.getValue(),
                                                sector: sectorRef.current.getValue(),
                                                credits: creditsRef.current.getValue(),
                                                question: JSON.stringify(listExample),
                                            })
                                            .then(() => {
                                                toastUpdate.success('Sửa đề thi thành công !');
                                                navigate(routes.myExam);
                                            })
                                            .catch(() => {
                                                toastUpdate.console.error('Sửa đề thi thất bại !');
                                            });
                                    };
                                    setPopupWarning((prev) => ({
                                        ...prev,
                                        state: true,
                                        message: 'Bạn muốn lưu tất cả thay đổi không !',
                                        event: () => {
                                            update();
                                        },
                                    }));
                                }}
                            >
                                Lưu thay đổi
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Popup warning */}
            <Popup
                open={popupWarning.state}
                onClose={() => {
                    popupWarning.next();
                    setPopupWarning((prev) => ({ ...prev, state: false }));
                }}
            >
                {popupWarning.message}
                <div className={cx('popup-warning')}>
                    <Button
                        primary
                        onClick={() => {
                            save();
                            setPopupWarning((prev) => ({ ...prev, state: false }));
                            popupWarning.next();
                            popupWarning.event();
                        }}
                    >
                        Lưu
                    </Button>
                    <Button
                        danger
                        onClick={() => {
                            setPopupWarning((prev) => ({ ...prev, state: false }));
                            popupWarning.next();
                        }}
                    >
                        Hủy
                    </Button>
                </div>
            </Popup>
        </div>
    );
}

export default EditExample;
