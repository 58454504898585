import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames/bind';

import styles from './OTPInput.module.scss';
const cx = classNames.bind(styles);
function OTPInput({ ...props }, ref) {
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const inputRef = useRef();
    const [error, setError] = useState('');
    useImperativeHandle(ref, () => ({
        getValue() {
            return otp.join(',').replace(/,/g, '');
        },
        validate() {
            if (otp.includes('')) {
                setError('Vui lòng nhập đầy đủ mã OTP.');
                return false;
            }
            setError('');
            return true;
        },
    }));
    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        // Focus vào ô tiếp theo
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };
    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp);
            const handler = setTimeout(() => {
                if (e.target.previousSibling) {
                    e.target.previousSibling.focus();
                    clearTimeout(handler);
                }
            }, 1);
        }
    };
    return (
        <>
            <div className={cx('otp-input')}>
                {otp.map((data, index) => {
                    return (
                        <input
                            ref={inputRef}
                            className={cx('otp-field')}
                            type="text"
                            name="otp"
                            maxLength="1"
                            key={index}
                            value={data}
                            onChange={(e) => handleChange(e.target, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onFocus={(e) => e.target.select()}
                        />
                    );
                })}
            </div>
            {error && <p className={cx('error')}>{error}</p>}
        </>
    );
}

export default forwardRef(OTPInput);
