import classNames from 'classnames/bind';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import useDebounce from '../../hooks/useDebounce';
import { useGlobalContext } from '../../hooks/useGlobalContext';
import styles from './Input.module.scss';
const cx = classNames.bind(styles);

function Input({ style, validates, title, toast, type, data, defaultValue, children, ...props }, ref) {
    let Component = 'input';

    const { showToast } = useGlobalContext();
    const [inputValue, setInputValue] = useState(() => {
        if (defaultValue) {
            return defaultValue;
        } else {
            if (type === 'select') {
                return data[0].value || data[0].title;
            } else {
                return '';
            }
        }
    });

    const inputRef = useRef();
    const [error, setError] = useState('');
    let classes = cx('input-group', { red: error });
    let debounceValue = useDebounce(inputValue, 500);
    const isFirstRender = useRef(true);
    const validate = useCallback(
        (validate = validates) => {
            if (validates) {
                for (const key in validate) {
                    if (Object.hasOwnProperty.call(validate, key)) {
                        const message = validate[key];
                        if (Validation[key]) {
                            if (typeof message === 'object') {
                                const messageError = Validation[key](message.value, message.message);
                                if (messageError !== false) {
                                    setError(messageError);
                                    toast && showToast(messageError);
                                    return false;
                                }
                            } else {
                                const messageError = Validation[key](message);

                                if (messageError !== false) {
                                    setError(messageError);
                                    toast && showToast(messageError);

                                    return false;
                                }
                            }
                        } else {
                            console.error('Not validate', key);
                        }
                    }
                }
            } else {
                console.error('You dont props validate');
            }
            setError('');
            return true;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inputValue, validates],
    );
    useImperativeHandle(ref, () => ({
        getValue() {
            return inputValue;
        },
        validate,
    }));

    const Validation = {
        require: (message) => (inputValue ? false : message),
        email: (message) => {
            var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
            return regexEmail.test(inputValue) ? false : message;
        },
        min: (value, message) => (inputValue.length >= value ? false : message),
        confirm: (value, message) => {
            const valueConfirm = value.current.getValue();
            return valueConfirm === inputValue ? false : message;
        },
        showMessage: (message) => message,
    };

    useEffect(() => {
        if (validates) {
            inputRef.current.onblur = () => {
                validate();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);
    const _props = {};

    _props.type = type;
    if (type === 'radio') {
    } else if (type === 'select') {
        Component = type;
        if (!data) {
            data = [{ title: 'Không có dữ liệu' }];
        }
    }
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (validates) {
            validate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceValue]);
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    return (
        <div className={cx('wraper')} style={style}>
            <div className={classes}>
                {title && <label className={cx('label')}>{title}</label>}
                <Component
                    ref={inputRef}
                    placeholder=" "
                    value={inputValue}
                    onChange={(e) => handleInputChange(e)}
                    {..._props}
                    {...props}
                >
                    {type !== 'select'
                        ? null
                        : data.map((item, index) => (
                              <option key={index} value={item ? item.value : item.title}>
                                  {item.title}
                              </option>
                          ))}
                </Component>
            </div>
            <div className={cx('err-mesage')}>{error && error}</div>
        </div>
    );
}

export default forwardRef(Input);
