import classNames from 'classnames/bind';
import { useState } from 'react';

import styles from './Default.module.scss';
import Header from '../Components/Header';
import SideBar from '../Components/SideBar';

const cx = classNames.bind(styles);

function Default({ children, sideBarMini }) {
    const [stateSideBar, setStateSideBar] = useState(false);
    const handleToggleSideBar = () => {
        setStateSideBar(!stateSideBar);
    };
    const classes = cx('wraper', {
        hide: sideBarMini,
    });
    return (
        <>
            <Header onToggleSideBar={handleToggleSideBar}></Header>
            <div className={classes}>
                <SideBar stateSideBar={stateSideBar} sideBarMini={sideBarMini}></SideBar>
                <div className={cx('content')}>{children}</div>
            </div>
        </>
    );
}

export default Default;
