import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { publicRoutes, privateRoutes } from './routes';
import DefaultLayout from './layout/Default';
import { Fragment, useEffect } from 'react';
import { AuthProvider } from './hooks/useAuth';
import ProtectedRoute from './components/ProtectedRoute';
import { GlobalProvider } from './hooks/useGlobalContext';
import { useLocalStorage } from './hooks/useLocalStorage';

function App() {
    const [theme, setTheme] = useLocalStorage('theme-dark', false);
    useEffect(() => {
        let classList = document.body.classList;
        theme ? classList.add('theme-dark') : classList.remove('theme-dark');
        const handleUnload = () => {
            setTheme(classList.contains('theme-dark'));
        };

        window.addEventListener('unload', handleUnload);

        return () => {
            window.removeEventListener('unload', handleUnload);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLayout = (item) => {
        let PageLayout = DefaultLayout;
        const Layout = item.layout;
        if (Layout === null) {
            PageLayout = Fragment;
        } else if (Layout) {
            PageLayout = Layout;
        }
        return PageLayout;
    };
    return (
        <BrowserRouter>
            <GlobalProvider>
                <div className="App">
                    <AuthProvider>
                        <Routes>
                            {publicRoutes.map((item, index) => {
                                const Component = item.component;
                                let PageLayout = handleLayout(item);
                                return (
                                    <Route
                                        key={index}
                                        path={item.path}
                                        element={
                                            <PageLayout {...item.props}>
                                                <Component></Component>
                                            </PageLayout>
                                        }
                                    ></Route>
                                );
                            })}
                            {privateRoutes.map((item, index) => {
                                const Component = item.component;
                                let PageLayout = handleLayout(item);
                                return (
                                    <Route
                                        key={index}
                                        path={item.path}
                                        element={
                                            <ProtectedRoute>
                                                <PageLayout {...item.props}>
                                                    <Component></Component>
                                                </PageLayout>
                                            </ProtectedRoute>
                                        }
                                    ></Route>
                                );
                            })}
                        </Routes>
                    </AuthProvider>
                </div>
            </GlobalProvider>
        </BrowserRouter>
    );
}

export default App;
