import classNames from 'classnames/bind';
import { faCircleArrowLeft, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

import styles from './CardContest.module.scss';
import Button from '../../../../components/Button';
import AnswerList from '../AnswerList';
const cx = classNames.bind(styles);

function CardContest(
    {
        listExample,
        curentPart,
        curentQuestion,
        setCurentQuestion,
        setListExample,
        timeSkipQuestion,
        className,
        setCurentPart,
        setResult = () => {},
    },
    ref,
) {
    const [correct, setCorrect] = useState(0);
    const [wrong, setWrong] = useState(0);

    useImperativeHandle(ref, () => {
        return {
            getCorrect: correct,
            getWrong: wrong,
        };
    });

    const handleChooseAnswer = (choose, type) => {
        type.correct ? setCorrect((prev) => prev + 1) : setWrong((prev) => prev + 1);
        type.correct
            ? setResult((prev) => ({ ...prev, correct: prev.correct + 1 }))
            : setResult((prev) => ({ ...prev, wrong: prev.wrong + 1 }));
        setListExample((prev) => {
            const newListExample = [...prev];

            newListExample[curentPart].questions[curentQuestion] = {
                ...newListExample[curentPart].questions[curentQuestion],
                choose: choose,
                ...type,
            };
            return newListExample;
        });

        timeSkipQuestion &&
            setTimeout(() => {
                handleChangeQuestion('next');
            }, timeSkipQuestion * 1000);
    };

    const handleChangeQuestion = (type) => {
        const num = type === 'next' ? 1 : -1;
        if (
            (curentQuestion < listExample[curentPart].questions.length - 1 || type !== 'next') &&
            (curentQuestion > 0 || type === 'next')
        ) {
            setCurentQuestion((prev) => prev + num);
        } else {
            if (curentPart < listExample.length - 1 && type === 'next') {
                setCurentPart((prev) => prev + num);
                setCurentQuestion(0);
            }
            if (curentPart > 0 && type !== 'next') {
                setCurentPart((prev) => prev + num);
                setCurentQuestion(listExample[curentPart - 1].questions.length - 1);
            }
        }
    };
    const handleKeyDown = useCallback(
        (e) => {
            if (!(curentPart === 0 && curentQuestion === 0)) {
                if (e.key === 'ArrowLeft') {
                    handleChangeQuestion('prev');
                }
            }
            if (
                !(
                    curentPart === listExample.length - 1 &&
                    curentQuestion === listExample[curentPart].questions.length - 1
                )
            ) {
                if (e.key === 'ArrowRight') {
                    handleChangeQuestion('next');
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [curentPart, curentQuestion],
    );
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleKeyDown]);
    return (
        <div className={cx('contest', className)}>
            <div className={cx('header')}>
                <div>Câu {curentQuestion + 1}</div>
            </div>
            <div className={cx('content')}>
                <div dangerouslySetInnerHTML={{ __html: listExample[curentPart].questions[curentQuestion].name }}></div>
                <hr></hr>
                <AnswerList
                    question={listExample[curentPart].questions[curentQuestion]}
                    handleChooseAnswer={handleChooseAnswer}
                ></AnswerList>
                {/* <div className={cx('answer-list')}>
                    {listExample[curentPart].questions[curentQuestion].answers.map((answer, index) => {
                        const isCorrect = choose + 1 && answer.is_correct;
                        return (
                            <Button
                                onClick={() => {
                                    handleChooseAnswer(index, {
                                        correct: answer.is_correct,
                                    });
                                }}
                                disable={choose + 1}
                                userselect={choose + 1}
                                key={index}
                                className={cx('button')}
                            >
                                <div className={cx('answer-item')}>
                                    <input type="radio" readOnly checked={choose === index}></input>
                                    <div
                                        className={cx({
                                            correct: isCorrect,
                                            wrong: !isCorrect && choose === index,
                                        })}
                                        dangerouslySetInnerHTML={{ __html: answer.option }}
                                    ></div>
                                </div>
                            </Button>
                        );
                    })}
                </div> */}
            </div>
            <div className={cx('example-control')}>
                <Button
                    onClick={() => handleChangeQuestion('prev')}
                    disable={curentPart === 0 && curentQuestion === 0}
                    primary
                    leftIcon={faCircleArrowLeft}
                >
                    Trước
                </Button>
                <Button
                    onClick={() => handleChangeQuestion('next')}
                    disable={
                        curentPart === listExample.length - 1 &&
                        curentQuestion === listExample[curentPart].questions.length - 1
                    }
                    primary
                    rightIcon={faCircleArrowRight}
                >
                    Sau
                </Button>
            </div>
        </div>
    );
}

export default forwardRef(CardContest);
