import { useRef } from 'react';

import Input from '../../components/Input';
import Button from '../../components/Button';
function TestComponent() {
    const username = useRef();
    const password = useRef();
    const handleSubmit = () => {
        //console.log('submit');
        console.log(username);
    };
    return (
        <>
            <Input
                title="Tự động chuyển câu"
                type="select"
                defaultValue={1}
                ref={username}
                data={[
                    { title: 'Tắt', value: 0 },
                    { title: '1s', value: 1 },
                    { title: '2s', value: 2 },
                    { title: '3s', value: 3 },
                ]}
            ></Input>
            <Input
                ref={password}
                validate={{
                    require: 'Bắt buộc nhập trường này !',
                    confirm: { value: username, message: 'Ký tự nhập vào không trùng khớp' },
                }}
                title="Tài khoản"
                placeholder="Tên tài khoản"
            ></Input>
            <Button primary onClick={handleSubmit}>
                Click
            </Button>
        </>
    );
}

export default TestComponent;
