import classNames from 'classnames/bind';

import images from '../../assets/images';
import styles from './Home.module.scss';
const cx = classNames.bind(styles);
function Home() {
    return (
        <div className={cx('wraper')}>
            <img src={images.background} alt="background"></img>
        </div>
    );
}
export default Home;
