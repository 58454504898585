import classNames from 'classnames/bind';
import { Link, useLocation } from 'react-router-dom';
import { useRef, useState } from 'react';
import 'react-toastify/ReactToastify.min.css';

import Button from '../../components/Button';
import { useAuth } from '../../hooks/useAuth';
import styles from './Login.module.scss';
import Input from '../../components/Input';
import { useGlobalContext } from '../../hooks/useGlobalContext';
import { routes } from '../../config';
const cx = classNames.bind(styles);
function Login() {
    const state = useLocation();
    const { login } = useAuth();
    const [loginFalse, setLoginFalse] = useState(false);
    const email = useRef();
    const password = useRef();
    const btnLogin = useRef();
    const { showToast } = useGlobalContext();
    if (state) {
        showToast(state.message);
    }
    const handleLogin = async () => {
        setLoginFalse(true);
        await login({ email: email.current.getValue(), password: password.current.getValue() });
        setLoginFalse(false);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            btnLogin.current.click();
        }
    };

    return (
        <div className={cx('wraper')}>
            <div>
                <Input
                    ref={email}
                    placeholder="Email hoặc số điện thoại"
                    validates={{ require: 'Bắt buộc nhập email !', email: 'Đây không phải email !' }}
                    onKeyDown={handleKeyDown}
                ></Input>
                <Input
                    ref={password}
                    type="password"
                    placeholder="Mật khẩu"
                    validates={{
                        require: 'Bắt buộc nhập mật khẩu !',
                        min: { value: 6, message: 'Mật khẩu tối thiểu 6 ký tự !' },
                    }}
                    onKeyDown={handleKeyDown}
                ></Input>
            </div>

            <div className={cx('login-wraper')}>
                <Button
                    ref={btnLogin}
                    onClick={handleLogin}
                    disable={loginFalse}
                    className={cx('btn-login')}
                    primary
                    validateInput={[email, password]}
                >
                    Đăng nhập
                </Button>
            </div>
            <div className={cx('forgot-pwr')}>
                <Link to={''}>Quên mật khẩu</Link>
            </div>
            <hr></hr>
            <div className={cx('register-wraper')}>
                <Button to={routes.register} primary className={cx('btn-register')}>
                    Đăng ký tài khoản
                </Button>
            </div>
        </div>
    );
}

export default Login;
