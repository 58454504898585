import classNames from 'classnames/bind';
import { useCallback, useEffect, useState } from 'react';

import styles from './MyExample.module.scss';
import CardExample from '../../components/CardExample';
import request from '../../utils/request';
const cx = classNames.bind(styles);

function MyExample() {
    const [listMyExample, setListMyExample] = useState([]);
    const getExample = useCallback(() => {
        request
            .get('get-myexample')
            .then((data) => {
                setListMyExample(data.data);
            })
            .catch(() => {});
    }, []);
    useEffect(() => {
        getExample();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className={cx('wraper')}>
            {listMyExample.map((example, index) => {
                return <CardExample getExample={getExample} key={index} example={example} myExample></CardExample>;
            })}
        </div>
    );
}

export default MyExample;
