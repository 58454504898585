import classNames from 'classnames/bind';
import styles from './Time.module.scss';

import React, { useState, useEffect, memo, forwardRef, useImperativeHandle, useCallback } from 'react';
const cx = classNames.bind(styles);
function Time({ notAlwayRunning, finishFn = () => {}, className, time = 0, countDown, children }, ref) {
    const [seconds, setSeconds] = useState(time);
    const [clock, setClock] = useState({});
    const classes = cx('wraper', className);
    const [idInterval, setIdInterval] = useState();
    const handleStart = () => {
        clearInterval(idInterval);
        const interval = setInterval(() => {
            setSeconds((prevTime) => {
                const temp = countDown ? -1 : 1;
                return prevTime + temp;
            });
        }, 1000);
        setIdInterval(interval);
    };

    useEffect(() => {
        if (!notAlwayRunning) handleStart();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        return () => {
            clearInterval(idInterval);
        };
    }, [idInterval]);
    const getTimeBySec = useCallback((seconds) => {
        return {
            hours: Math.floor(seconds / 3600),
            minutes: Math.floor((seconds % 3600) / 60),
            seconds: seconds % 60,
        };
    }, []);
    useEffect(() => {
        setClock(getTimeBySec(seconds));
        if (seconds <= 0 && countDown) {
            clearInterval(idInterval);
            finishFn();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seconds]);
    useImperativeHandle(ref, () => ({
        restart() {
            setSeconds(time);
            handleStart();
        },
        stop() {
            clearInterval(idInterval);
        },
        start(times) {
            setSeconds(times);
            handleStart();
        },
        getTime() {
            let currentTime;
            if (countDown) {
                currentTime = getTimeBySec(time - seconds);
                return `${currentTime.hours}:${currentTime.minutes}:${currentTime.seconds}`;
            } else {
                currentTime = getTimeBySec(seconds);
                return `${currentTime.hours}:${currentTime.minutes}:${currentTime.seconds}`;
            }
        },
    }));
    return (
        <div className={classes}>
            {children}
            <time>
                {clock.hours} : {clock.minutes} : {clock.seconds}
            </time>
        </div>
    );
}

export default memo(forwardRef(Time));
